import {EVService} from './services/ev.service';
import {InstallminisensePage} from './installminisense/installminisense.page';
import {Component, Inject, OnInit} from '@angular/core';

import {Platform, ModalController, MenuController, AlertController} from '@ionic/angular';
import {Auth0Client} from '@auth0/auth0-spa-js';
import {environment} from '../environments/environment';

import {SwUpdate} from '@angular/service-worker';
import {ConnectionService} from 'ng-connection-service';
import {ColorSchemeService} from './services/colorscheme.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {LangageService} from './services/langage.service';
import {MixpanelService} from './services/mixpanel.service';
import { UserService } from './services/user.service';
import {UserRolesEnum} from './enums/UserRolesEnum';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    private auth0Client: Auth0Client;
    segmentModel = "home";
    display = false;
    currentTheme;
    langage = 'fr';
    isAuthenticated = false;
    status = 'ONLINE';
    isConnected = true;
    version: string;
    profile: string;
    isInstallateur: boolean = false;
    isAdmin: boolean = false;
    isUrbasenseCorporate: boolean = false;


    constructor(
        @Inject(DOCUMENT) private doc: Document,
        private colorSchemeService: ColorSchemeService,
        private platform: Platform,
        private connectionService: ConnectionService,
        public auth: AuthService,
        public userService: UserService,
        private alertController: AlertController,
        private modalController: ModalController,
        public updates: SwUpdate,
        private MixpanelService: MixpanelService,
        public menu: MenuController,
        private ev: EVService,
        private translate: TranslateService,
        public langageservice: LangageService
    ) {

        this.version = environment.version;


        this.colorSchemeService.load();
        this.currentTheme = this.colorSchemeService.currentActive();


        this.display = this.currentTheme === this.colorSchemeService.THEMES.LIGHT;


        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            this.status = this.isConnected ? 'ONLINE' : 'OFFLINE';
        });
    }

    trackMix(str) {
        this.MixpanelService.track(str);
    }

    setLangage() {
        this.translate.setDefaultLang(this.langage);
        this.langageservice.setLangage(this.langage);
    }

    async ngOnInit() {
        this.langage = this.langageservice.getLangage();
        this.updates.available.subscribe(event => {
            this.presentAlertConfirm();
        });
        this.auth.user$.subscribe(user => {
            this.isInstallateur = this.userService.hasRole(user, UserRolesEnum.INSTALLATEUR);
            this.isAdmin = this.userService.hasRole(user, UserRolesEnum.ADMIN);
            this.isUrbasenseCorporate = this.userService.hasRole(user, UserRolesEnum.URBASENSE_CORPORATE);
        });

    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: this.translate.instant('Mise à jour disponible'),
            message: this.translate.instant('Une mise à jour est disponible, la page va se recharger'),
            buttons: [
                {
                    text: this.translate.instant('ok'),
                    handler: () => {
                        window.location.reload();
                    }
                }
            ]
        });

        await alert.present();
    }

    toggleTheme() {
        this.colorSchemeService.update((this.display ?
                // Switch to either Light or Dark color theme
                this.colorSchemeService.THEMES.LIGHT : this.colorSchemeService.THEMES.DARK
        ));
        this.currentTheme = this.colorSchemeService.currentActive();
    }




    async openModalInstallation() {
        const modal = await this.modalController.create({
            component: InstallminisensePage,
            cssClass: 'my-custom-class'
        });
        return await modal.present();
    }


    logout() {
        this.auth.logout({returnTo: this.doc.location.origin});
    }
}
