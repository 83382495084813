import { Component, OnInit } from '@angular/core';
import { GenericLoaderService } from '../generic-loader.service';

@Component({
  selector: 'app-generic-loader',
  templateUrl: './generic-loader.component.html',
  styleUrls: ['./generic-loader.component.scss'],
})
export class GenericLoaderComponent implements OnInit {
  loading$ = this.loaderService.loading$;
  constructor(private loaderService: GenericLoaderService) { }

  ngOnInit() {}

}
