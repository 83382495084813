<ion-toolbar color="primary">
    <ion-buttons slot="start">
        <ng-container *ngIf="params?.url">
            <ion-button *ngIf="previousUrl" [routerLink]="previousUrl">
                <ion-icon style="font-size: 32px;" color="light" name="arrow-back-outline"></ion-icon>
            </ion-button>
            <ion-button *ngIf="!previousUrl" (click)="goBack()">
                <ion-icon style="font-size: 32px;" color="light" name="arrow-back-outline"></ion-icon>
            </ion-button>
        </ng-container>
        <a class="header-logo boldSf ion-text-wrap" [routerLink]="['/accueil']">
            <img src="/assets/logo_urbasense.png" class="header-logo" alt="logo urbasense">
            <h2 data-from="left">{{ getPageTitleExcerpt() }}</h2>
        </a>
    </ion-buttons>
    <ion-buttons slot="end">
        <ion-menu-button></ion-menu-button>
    </ion-buttons>
</ion-toolbar>
