import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Color, ColorVariable, RGB} from "./colors/color";
import {IonicColorKeyEnum} from "../enums/IonicColorKeyEnum";

const COLOR_ATTR_MAP = {
    value: '',
    valueRgb: '-rgb',
    contrast: '-contrast',
    contrastRgb: '-contrast-rgb',
    shade: '-shade',
    tint: '-tint',
};

const DEFAULT_PRIMARY_COLOR = '#59bb78';

@Injectable({
    providedIn: 'root'
})
export class ColorSchemeService {
    primaryColor$: BehaviorSubject<string>;

    public readonly THEMES = {
        LIGHT: 'light',
        DARK: 'dark',
    };

    private renderer: Renderer2;
    private colorScheme: string;
    // Define prefix for clearer and more readable class names in scss files
    private colorSchemePrefix = 'color-scheme-';
    // Local Storage key for theme preference
    private KEY = 'prefers-colors';

    constructor(rendererFactory: RendererFactory2) {
        // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
        this.renderer = rendererFactory.createRenderer(null, null);

        // Initialize primary color to a default value
        this.primaryColor$ = new BehaviorSubject<string>(DEFAULT_PRIMARY_COLOR);
    }

    _detectPrefersColorScheme() {
        // Detect if prefers-color-scheme is supported
        if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
            // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise set to light.
            this.colorScheme = window.matchMedia('(prefers-color-scheme: dark)')
                .matches ? this.THEMES.DARK : this.THEMES.LIGHT;
        } else {
            // If the browser doesn't support prefers-color-scheme, set it as default to dark
            this.colorScheme = this.THEMES.DARK;
        }
    }

    _setColorScheme(scheme) {
        this.colorScheme = scheme;
        // Save prefers-color-scheme to localStorage
        localStorage.setItem(this.KEY, scheme);
    }

    _getColorScheme() {
        // Check if any prefers-color-scheme is stored in localStorage
        if (localStorage.getItem('prefers-color')) {
            // Save prefers-color-scheme from localStorage
            this.colorScheme = localStorage.getItem(this.KEY);
        } else {
            // If no prefers-color-scheme is stored in localStorage, try to detect OS default prefers-color-scheme
            this._detectPrefersColorScheme();
        }
    }

    load() {
        this._getColorScheme();
        this.renderer.addClass(document.body, this.colorScheme);
    }

    update(scheme): void {
        // Remove the old color-scheme class
        this.renderer.removeClass(document.body, this.colorScheme);
        // Add the new / current color-scheme class
        this.renderer.addClass(document.body, scheme);
        this._setColorScheme(scheme);
    }

    currentActive() {
        return this.colorScheme;
    }

    updatePrimaryColor(color: string = DEFAULT_PRIMARY_COLOR) {
        this.primaryColor$.next(color);
        this.applyColor(
            IonicColorKeyEnum.PRIMARY,
            this.generateColor(color),
        );
    }

    generatePropertyName(colorName: string = IonicColorKeyEnum.PRIMARY): string {
        return `--ion-color-${colorName.toLowerCase()}`;
    }

    applyColor(colorName: string, colorValues: ColorVariable): void {
        Object.keys(COLOR_ATTR_MAP).forEach(k => {
            const baseName = this.generatePropertyName(colorName);
            console.log(baseName + COLOR_ATTR_MAP[k], colorValues[k]);
            document.documentElement.style.setProperty(
                baseName + COLOR_ATTR_MAP[k],
                colorValues[k],
            );
        });
    }

    generateColor(value: string): ColorVariable {
        const color = new Color(value);
        const contrast = color.contrast();
        const tint = color.tint();
        const shade = color.shade();

        return {
            value,
            valueRgb: this.rgbToString(color.rgb),
            contrast: contrast.hex,
            contrastRgb: this.rgbToString(contrast.rgb),
            tint: tint.hex,
            shade: shade.hex,
        };
    }

    rgbToString(c: RGB): string {
        return `${c.r},${c.g},${c.b}`;
    }

}
