

import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";
import { tap, catchError, finalize } from "rxjs/operators";
import { LoaderService } from './loader.service';

@Injectable()
export class UrbasenseHttpRequestInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.loaderService.show();

      return next.handle(req).pipe(
        finalize(() => this.loaderService.hide())
      );
    }
}